import React, { useCallback, useEffect } from 'react';
import { Alert, Button, Space, Typography } from 'antd';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { openExternalLink } from '../../../utils/linkOpener.ts';

export type ConnectionState = 'pending' | 'ok' | 'failure';

export const EcardConnectionCheck: React.FC<{
  connectionState: ConnectionState;
  onConnectionStateChanged: (connectionState: ConnectionState) => void;
}> = ({ connectionState, onConnectionStateChanged }) => {
  const testConnection = useCallback(async () => {
    onConnectionStateChanged('pending');
    try {
      await fetch('https://services.ecard.sozialversicherung.at/servicemanager/1?wsdl', {
        signal: AbortSignal.timeout(4_000),
      });
      onConnectionStateChanged('ok');
    } catch (e) {
      // also try test instance (for development)
      try {
        await fetch('https://services-a.ecard-test.sozialversicherung.at/servicemanager/1?wsdl', {
          signal: AbortSignal.timeout(4_000),
        });
        onConnectionStateChanged('ok');
      } catch (e) {
        onConnectionStateChanged('failure');
      }
    }
  }, [onConnectionStateChanged]);

  useEffect(() => {
    testConnection();
  }, [testConnection]);

  if (connectionState === 'pending') {
    return (
      <Alert showIcon icon={<LoadingOutlined />} type="info" message="Bitte warten, die Verbindung wird überprüft" />
    );
  }

  if (connectionState === 'ok') {
    return (
      <Alert
        showIcon
        type="success"
        message="Das Gesundheits-Informations-Netz (GIN) ist von Ihrem Standort erreichbar"
      />
    );
  }

  // failure
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Alert
        showIcon
        type="error"
        message="Das Gesundheits-Informations-Netz (GIN) ist von Ihrem Standort nicht erreichbar"
        action={
          <Button icon={<ReloadOutlined />} onClick={testConnection}>
            Erneut versuchen
          </Button>
        }
      />
      <Typography.Paragraph type="secondary" style={{ margin: 0 }}>
        Mögliche Fehlerquellen:
        <ul style={{ margin: 0 }}>
          <li>
            <a
              onClick={() =>
                openExternalLink('https://www.chipkarte.at/cdscontent/?contentid=10007.897509&portal=ecardportal')
              }
            >
              Zertifikate
            </a>{' '}
            sind nicht ordnungsgemäß installiert
          </li>
          <li>
            Befinden Sie sich vor Ort in Ihrer Praxis oder im Labor? - Die Einrichtung kann nur lokal mit einem
            bestehenden e-card Anschluss durchgeführt werden.
          </li>
          <li>
            Ist Ihr Netzwerk richtig konfiguriert? - Überprüfen Sie, ob Sie sich auf der{' '}
            <a onClick={() => openExternalLink('https://services.ecard.sozialversicherung.at/')}>
              e-card Weboberfläche
            </a>{' '}
            anmelden können.
          </li>
        </ul>
      </Typography.Paragraph>
    </Space>
  );
};
