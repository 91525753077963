import React, { useState } from 'react';
import { EcardSetupAssistant } from './ecard/EcardSetupAssistant.tsx';
import { EcardConfigs } from './ecard/EcardConfigs.tsx';

// # 4. lesegerät auswählen und adresse speichern
// # 5. karte stecken und PIN angeben -> check
// # 6. dialogdata holen und ordinationen anzeigen und auswählen => bei test vergleichen ob match
// # 7. taetigkeitsberech, ordiId auswählen
// # 8. daten beim zuweiser speichern
//
// # berechtigungen für Sas checken SAS.CORE => bei test anzeigen
// # API erweitern und in labrador rest client erzeugen lassen und controller erzeugen lassen -> file kopieren
// brauchen wir irgendwo die VPNR gespeichert?
// retry bei abfragen, wo dialog benötigt wird: cached dialog -> request -> clear cache -> neuer dialog -> request

export const Ecard: React.FC = () => {
  const [showAssistant, setShowAssistant] = useState(false);

  if (showAssistant) {
    return <EcardSetupAssistant onHideAssistant={() => setShowAssistant(false)} />;
  }
  return <EcardConfigs onShowAssistant={() => setShowAssistant(true)} />;
};
