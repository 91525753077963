import React, { useState } from 'react';
import { ConnectionState, EcardConnectionCheck } from './EcardConnectionCheck.tsx';
import { css } from '@emotion/css';
import { EcardConfig } from './EcardConfigs.tsx';
import { Space } from 'antd';

export const EcardTest: React.FC<{ config: EcardConfig }> = ({ config }) => {
  const [connectionState, setConnectionState] = useState<ConnectionState>('pending');
  // TODO: make test with real reader
  return (
    <Space
      direction="vertical"
      className={css`
        width: 500px;
      `}
    >
      <EcardConnectionCheck connectionState={connectionState} onConnectionStateChanged={setConnectionState} />
      {connectionState === 'failure' && <div>{config.cardReaderAddress}</div>}
    </Space>
  );
};
