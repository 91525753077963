import React from 'react';
import {
  AuditOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  DesktopOutlined,
  ExperimentOutlined,
  FileDoneOutlined,
  MedicineBoxOutlined,
  IdcardOutlined,
  NotificationOutlined,
  PlusCircleOutlined,
  ProfileOutlined,
  SearchOutlined,
  SendOutlined,
  SettingOutlined,
  SwapOutlined,
  TeamOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Menu, theme } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { hasFrontDeskRole, hasSomeRole, Role } from '../utils/user';
import { ItemType } from 'antd/es/menu/interface';
import { useAuth } from 'react-oidc-context';

const { useToken } = theme;

export const MainMenu: React.FC = () => {
  const { token } = useToken();
  const auth = useAuth();
  const location = useLocation();
  const levels = location.pathname.split('/');
  const level1 = levels[1];
  const level2 = levels[2];
  const hasSubmenuItemsWithoutSubmenu = level1 === 'anforderung' || level1 === 'zuweiserauswahl';
  const isFrontDesk = hasFrontDeskRole(auth.user);

  const items: ItemType[] = [];
  items.push({
    key: 'dashboard',
    icon: <DashboardOutlined />,
    label: <Link to="/dashboard">Dashboard</Link>,
  });

  if (isFrontDesk || hasSomeRole([Role.ROLE_LR_MEDCOM], auth.user)) {
    items.push(
      {
        key: 'zuweiserauswahl',
        icon: <SwapOutlined />,
        label: <Link to="/zuweiserauswahl">Zuweiser auswählen</Link>,
      },
      {
        key: 'alle-offenen-pv-anforderungen',
        icon: <DatabaseOutlined />,
        label: <Link to="/alle-offenen-pv-anforderungen">Übersicht offener Anf.</Link>,
      }
    );
  }

  items.push(
    {
      key: 'anforderung',
      style: { fontWeight: 600 },
      icon: <PlusCircleOutlined style={{ color: token.colorPrimary }} />,
      label: <Link to="/anforderung">Neue Anforderung</Link>,
    },
    {
      key: 'offene-anforderungen',
      icon: <AuditOutlined />,
      label: <Link to="/offene-anforderungen">Offene Anforderungen</Link>,
    },
    {
      key: 'gesendete-anforderungen',
      icon: <SendOutlined />,
      label: <Link to="/gesendete-anforderungen">Gesendete Anforderungen</Link>,
    },
    {
      key: 'patient-suche',
      icon: <SearchOutlined />,
      label: <Link to="/patient-suche">Patient suchen</Link>,
    }
  );

  if (hasSomeRole([Role.ROLE_LR_MEDCOM, Role.ROLE_LR_LAB_ADMIN, Role.ROLE_LR_FRONT_DESK], auth.user)) {
    items.push({
      key: 'fibu-export',
      icon: <CloudDownloadOutlined />,
      label: <Link to="/fibu-export">Fibu-Export</Link>,
    });
  }

  if (
    hasSomeRole([Role.ROLE_LR_MEDCOM, Role.ROLE_LR_LAB_ADMIN, Role.ROLE_LR_USER, Role.ROLE_LR_FRONT_DESK], auth.user)
  ) {
    const settings: ItemType[] = [];
    if (hasSomeRole([Role.ROLE_LR_MEDCOM, Role.ROLE_LR_LAB_ADMIN, Role.ROLE_LR_USER], auth.user)) {
      settings.push({
        key: 'einstellungen/zuweiser',
        icon: <TeamOutlined />,
        label: <Link to="/einstellungen/zuweiser">Zuweiser</Link>,
      });
    }

    settings.push(
      {
        key: 'einstellungen/profile',
        icon: <ProfileOutlined />,
        label: <Link to="/einstellungen/profile">Profile</Link>,
      },
      {
        key: 'einstellungen/sheets',
        icon: <FileDoneOutlined />,
        label: <Link to="/einstellungen/sheets">Sheets</Link>,
      },
      {
        key: 'einstellungen/ecard',
        icon: <IdcardOutlined />,
        label: <Link to="/einstellungen/ecard">e-card</Link>,
      }
    );

    if (hasSomeRole([Role.ROLE_LR_MEDCOM, Role.ROLE_LR_LAB_ADMIN], auth.user)) {
      settings.push({
        key: 'einstellungen/labor',
        icon: <ExperimentOutlined />,
        label: <Link to="/einstellungen/labor">Labor</Link>,
      });
    }

    items.push({
      key: 'einstellungen',
      icon: <ToolOutlined />,
      label: 'Einstellungen',
      children: settings,
    });
  }

  if (hasSomeRole([Role.ROLE_LR_MEDCOM], auth.user)) {
    items.push({
      key: 'admin',
      icon: <SettingOutlined />,
      label: 'Administration',
      children: [
        {
          key: 'admin/labors',
          icon: <ExperimentOutlined />,
          label: <Link to="/admin/labors">Labors</Link>,
        },
        {
          key: 'admin/krankenkassen',
          icon: <MedicineBoxOutlined />,
          label: <Link to="/admin/krankenkassen">Krankenkassen</Link>,
        },
        {
          key: 'admin/themes',
          icon: <DesktopOutlined />,
          label: <Link to="/admin/themes">Themes</Link>,
        },
        {
          key: 'admin/zuweiser',
          icon: <TeamOutlined />,
          label: <Link to="/admin/zuweiser">Zuweiser</Link>,
        },
        {
          key: 'admin/benutzer',
          icon: <UserOutlined />,
          label: <Link to="/admin/benutzer">Benutzer</Link>,
        },
        {
          key: 'admin/db-tool-import',
          icon: <CloudUploadOutlined />,
          label: <Link to="/admin/db-tool-import">DBTool Import</Link>,
        },
        {
          key: 'admin/system-status',
          icon: <NotificationOutlined />,
          label: <Link to="/admin/system-status">System Status</Link>,
        },
      ],
    });
  }

  return (
    <Menu
      items={items}
      mode="inline"
      selectedKeys={level2 && !hasSubmenuItemsWithoutSubmenu ? [level1 + '/' + level2] : [level1]}
      defaultOpenKeys={[level1]}
      style={{ border: 'none' }}
    />
  );
};
